import {
  Box,
  Button,
  Fab,
  FormControl,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  Stack,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router';
import { ONLINE_MEDIA_CLIPPING } from 'src/route';
import { useAppSelector } from 'src/app/hooks';
import useDownload from 'src/services/useDownload';
import { fillParamAdvanceSearchEmpty } from 'src/utils/fillParamAdvanceSearchEmpth';
import { useMemo, useState, useEffect } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useQueryUrl from 'src/hooks/useQuery';
import useOnlineMedia from 'src/services/useOnlineMedia';
import { useAlert } from 'src/hooks/useAlert';
import { generateWhatsappReportDoc } from 'src/utils/generateWhatsappReportDoc';
import { Packer } from 'docx';
import saveAs from 'file-saver';
import { generateArticleBySentimentDoc } from 'src/utils/generateArticleBySentimentDoc';
import { useQuery } from '@apollo/client';
import { ONLINE_MEDIA_GET_CLIPPING_BY_ID_V5 } from 'src/services/graphql/onlineMedia/query';
import TableSelectGroupPortal from 'src/components/TableSelectGroupPortal';
import { useFiltersByName } from 'src/hooks/useFiltersByName';
import { IGroupPortal } from 'src/models/groupPortal';
import useGroupPortal from 'src/services/useGroupPortal';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
    color: ${theme.header.textColor};
    padding: ${theme.spacing(1, 4, 0, 4)};
    right: 0;
    z-index: 5;
    box-shadow: ${theme.header.boxShadow};
    justify-content: space-between;
    width: 100%;
    background: ${theme.header.background};
    @media (min-width: ${theme.breakpoints.values.lg}px) {
        left: ${theme.sidebar.width};
        width: auto;
    }
`
);

function HeaderOnlineMediaClippingById() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();
  const id = pathname.split('/')[3];
  const { handleClickAlert } = useAlert();
  const query = useQueryUrl();
  const tabClipping = query.get('tab');

  const { data, loading, error } = useQuery(
    ONLINE_MEDIA_GET_CLIPPING_BY_ID_V5,
    { variables: { clippingId: id } }
  );

  const clippingName = data?.onlineMedia_getClippingByIdV5?.name ?? '';

  const { downloadCsvMediaOnline } = useDownload();
  const { downloadClusteringCsv } = useOnlineMedia();
  const projectId = useAppSelector((state) => state.users.project.id);
  const advanceSearchOnlineMedia = useAppSelector(
    (state) => state.storeAdvanceSearchOnlineMedia
  );
  const { dateRangeFormatted } = useAppSelector(
    (state) => state.analyticArguments
  );
  const articles = useAppSelector(
    (state) => state.storeOnlineMediaStream.articleList
  );

  const clusteringConfig = useAppSelector(
    (state) => state.storeOnlineMediaStream.clusteringConfig
  );

  const fillAdvanceSearchOnlineMedia = useMemo(() => {
    return fillParamAdvanceSearchEmpty(advanceSearchOnlineMedia);
  }, [advanceSearchOnlineMedia]);

  const { languageSelected } = useAppSelector(
    (state) => state.storeOnlineMediaStream
  );

  const labels = useAppSelector((state) => state.storeLabel.labelArticle.data);

  const payloads = {
    projectId,
    timezone: 7,
    start: dateRangeFormatted.start,
    end: dateRangeFormatted.end,
    granularity: {
      unit: 'day',
      value: 1
    },
    ...fillAdvanceSearchOnlineMedia,
    clippingId: id,
    labelList: fillAdvanceSearchOnlineMedia.labelList.map(
      (label) => label.id || label
    ),
    languageList: languageSelected === 'all-language' ? [] : [languageSelected]
  };

  const [anchorElDownload, setAnchorElDownload] =
    useState<HTMLButtonElement | null>(null);

  const handleClickDownload = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElDownload(event.currentTarget);
  };

  const handleCloseDownload = () => {
    setAnchorElDownload(null);
  };

  const handleDownload = () => {
    handleCloseDownload();
    tabClipping !== 'cluster'
      ? downloadCsvMediaOnline({
          variables: payloads
        })
      : downloadClusteringCsv({
          variables: {
            ...payloads,
            ...clusteringConfig
          },
          onCompleted: () =>
            handleClickAlert({
              horizontal: 'center',
              vertical: 'top',
              severity: 'success',
              message:
                'It may take some time for the report to be processed.\n The report can be downloaded from the Activity List on the top right corner.'
            }),
          onError(error) {
            handleClickAlert({
              horizontal: 'center',
              vertical: 'top',
              severity: 'error',
              message: 'Failed to download report, please try again.'
            });
            console.log(error.message);
          }
        });
  };

  const handleDownloadWhatsappReport = () => {
    const doc = generateWhatsappReportDoc(articles.data.result);
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `ONM - ${clippingName}.docx`);
    });
    handleCloseDownload();
  };

  const handleDownloadBySentiment = () => {
    const [selectedLabelId, ...rest] = advanceSearchOnlineMedia.labelList;
    const labelName = labels.find(
      (label) => label.id === selectedLabelId
    )?.displayName;
    const doc = generateArticleBySentimentDoc(
      articles.data.result,
      labelName ?? clippingName
    );
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `ONM - ${labelName ?? clippingName}.docx`);
    });
    handleCloseDownload();
  };

  const openDownload = Boolean(anchorElDownload);
  const idPopup = openDownload ? 'simple-popover' : undefined;

  const [openSelectGroup, setOpenSelectGroup] = useState(false);
  const { groupPortalList, selectedGroupPortalId } = useAppSelector(
    (state) => state.groupPortal
  );
  const { filteredList, handleFilter } = useFiltersByName<IGroupPortal>(
    groupPortalList.data
  );

  const { getGroupPortal } = useGroupPortal();
  useEffect(() => {
    getGroupPortal(projectId);
  }, [projectId]);
  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Grid container>
        <Grid item lg={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={2}
            alignItems="center"
            sx={{ mb: 2 }}
          >
            <Stack direction="row" alignItems="center">
              <Fab
                sx={{
                  background: theme.palette.common.white,
                  border: `1px solid ${theme.palette.grey[200]}`,
                  mr: theme.spacing(2)
                }}
                size="small"
                onClick={() => navigate(ONLINE_MEDIA_CLIPPING)}
              >
                <ArrowBackIcon />
              </Fab>
              <Typography variant="h1">{clippingName}</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item lg={12}>
          <Stack direction="row" justifyContent="space-between">
            <FormControl size="small" sx={{ flexBasis: '370px', mb: 2 }}>
              <Select
                name="group"
                open={openSelectGroup}
                onClose={() => setOpenSelectGroup(false)}
                onOpen={() => setOpenSelectGroup(true)}
                value={selectedGroupPortalId}
                defaultValue={selectedGroupPortalId}
              >
                <MenuItem
                  value={'_default'}
                  key={'_default'}
                  sx={{ display: 'none' }}
                >
                  Group
                </MenuItem>
                {groupPortalList.data.map((group) => (
                  <MenuItem
                    key={group.id}
                    value={group.id}
                    sx={{ display: 'none' }}
                  >
                    {group.displayName}
                  </MenuItem>
                ))}

                <Box sx={{ p: theme.spacing(2) }}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h4">Group</Typography>
                    {/* <Button
                    size="small"
                    variant="outlined"
                    onClick={(event) => {
                      handleClickOpenAddGroup();
                      event.stopPropagation();
                      event.preventDefault();
                    }}
                  >
                    New group +
                  </Button> */}
                  </Stack>
                  {/* <Input
                  startAdornment={
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  }
                  fullWidth
                  placeholder="Search Source"
                  onChange={(event) => {
                    handleFilter(event.target.value);
                  }}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                /> */}
                  <Box sx={{ mt: theme.spacing(1) }}>
                    <TableSelectGroupPortal
                      groupPortals={[
                        {
                          id: '_default',
                          name: 'All',
                          displayName: 'All',
                          portals: [],
                          isActive: false,
                          createdAt: '-'
                        },
                        ...filteredList
                      ]}
                      selectedGroupById={selectedGroupPortalId}
                      loading={groupPortalList.loading}
                      onClick={() => setOpenSelectGroup(false)}
                    />
                  </Box>
                </Box>
              </Select>
            </FormControl>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              startIcon={<DownloadIcon />}
              endIcon={<KeyboardArrowDownIcon />}
              sx={{
                minWidth: theme.spacing(6),
                height: 36,
                backgroundColor: '#FBFBFB',
                borderRadius: theme.spacing(1)
              }}
              onClick={handleClickDownload}
            >
              Download CSV
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Popover
        id={idPopup}
        open={openDownload}
        anchorEl={anchorElDownload}
        onClose={handleCloseDownload}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <List>
          <ListItemButton onClick={() => handleDownload()}>
            <ListItemText primary="CSV - All Metrics" />
          </ListItemButton>
          <ListItemButton onClick={() => handleDownloadWhatsappReport()}>
            <ListItemText primary="Word - Whatsapp Report" />
          </ListItemButton>
          <ListItemButton onClick={() => handleDownloadBySentiment()}>
            <ListItemText primary="Word - Articles by Label" />
          </ListItemButton>
        </List>
      </Popover>
    </HeaderWrapper>
  );
}

export default HeaderOnlineMediaClippingById;
