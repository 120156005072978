import {
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme
} from '@mui/material';
import moment from 'moment';
import { useAppDispatch } from 'src/app/hooks';
import { reducerUpdateAdvanceSearchOfmMediaList } from 'src/features/advanceSearchOfflineMedia';
import { reducerUpdateAdvanceSearchOnmMediaList } from 'src/features/advanceSearchOnlineMedia';
import { updateSelectedGroupPortalId } from 'src/features/groupPortal';
import { reducerUpdateMediaList } from 'src/features/offlineMediaStream';
import { IGroupPortal } from 'src/models/groupPortal';

interface IProps {
  groupPortals: IGroupPortal[];
  loading?: boolean;
  selectedGroupById: string | undefined;
  onClick: () => void;
}

const TableSelectGroupPortal = ({
  groupPortals,
  loading = false,
  selectedGroupById,
  onClick
}: IProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const handleClick = (group: IGroupPortal) => {
    onClick();
    dispatch(updateSelectedGroupPortalId(group.id));
    // update adv search media list according to selected group portal
    dispatch(reducerUpdateAdvanceSearchOfmMediaList(group.portals));
    // update adv search online media list also
    dispatch(reducerUpdateAdvanceSearchOnmMediaList(group.portals));
    // update filter media list stream panel ofm
    dispatch(reducerUpdateMediaList(group.portals));
  };

  return (
    <TableContainer component={Paper} sx={{ maxHeight: theme.spacing(30) }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>No</TableCell>
            <TableCell>Group</TableCell>
            <TableCell align="center">Added</TableCell>
            <TableCell align="center">Portal Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groupPortals.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              hover
              onClick={() => handleClick(row)}
            >
              <TableCell component="th" scope="row">
                <Radio
                  checked={row.id === selectedGroupById}
                  value={row.id}
                  name="group-portal"
                  size="small"
                  sx={{ color: theme.palette.primary.main }}
                />
              </TableCell>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{row.displayName}</TableCell>
              <TableCell align="center">
                {row.createdAt === '-'
                  ? '-'
                  : moment(row.createdAt).format('D/MM/YY')}
              </TableCell>
              <TableCell align="center">
                {row.id === '_default' ? '' : row.portals.length}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableSelectGroupPortal;
